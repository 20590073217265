import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { QueryOptions } from "src/app/global/domain/query.options";
import { ResourceService } from "src/app/global/services/resource.service";
import { WEB_API } from "src/app/global/services/web-api";
import { CobrancaJudicial } from "../domain/cobranca-judicial.model";
import { Produto } from "../domain/produto.model";
import { Page, ReturnAPI } from "../domain/return-api.model";
import { CobrancaJudicialSerializer } from "../serializables/cobranca-judicial.serializer";

@Injectable()
export class CobrancaJudicialService extends ResourceService<CobrancaJudicial> {
    static RESOURCE = 'cobranca-judicial';
    produto: Produto;
    produtoId: string;

    constructor(private http: HttpClient) {
        super(http, `${WEB_API}`, `${CobrancaJudicialService.RESOURCE}`, new CobrancaJudicialSerializer(CobrancaJudicial));
    }

    findAll(queryOption: QueryOptions): Observable<ReturnAPI<Page<CobrancaJudicial>>> {
        this.getProduto();
        return this.httpClient.get<ReturnAPI<Page<CobrancaJudicial>>>(
            `${WEB_API}/${CobrancaJudicialService.RESOURCE}?${queryOption.toQueryString()}&${this.produtoId}`,
        );
    }

    cadastrarCobrancaJudicial(cobrancaJudicial: CobrancaJudicial): Observable<ReturnAPI<CobrancaJudicial>> {
        this.getProduto();
        return this.httpClient.post<ReturnAPI<CobrancaJudicial>>(`${WEB_API}/${CobrancaJudicialService.RESOURCE}?${this.produtoId}`, cobrancaJudicial);
    }

    alterarValorCobrancaJudicial(cobrancaJudicial: CobrancaJudicial): Observable<ReturnAPI<CobrancaJudicial>> {
        this.getProduto();
        return this.httpClient.post<ReturnAPI<CobrancaJudicial>>(`${WEB_API}/${CobrancaJudicialService.RESOURCE}/alterar-valor?${this.produtoId}`, cobrancaJudicial);
    }

    alterarEmissaoCobrancaJudicial(cobrancaJudicial: CobrancaJudicial): Observable<ReturnAPI<CobrancaJudicial>> {
        this.getProduto();
        return this.httpClient.post<ReturnAPI<CobrancaJudicial>>(`${WEB_API}/${CobrancaJudicialService.RESOURCE}/alterar-emissao?${this.produtoId}`, cobrancaJudicial);
    }

    alterarQuantidadeParcelaJudicial(cobrancaJudicial: CobrancaJudicial): Observable<ReturnAPI<CobrancaJudicial>> {
        this.getProduto();
        return this.httpClient.post<ReturnAPI<CobrancaJudicial>>(`${WEB_API}/${CobrancaJudicialService.RESOURCE}/alterar-quantidade-parcela-judicial?${this.produtoId}`, cobrancaJudicial);
    }

    cancelarCobrancaJudicial(cobrancaJudicialId: any): Observable<ReturnAPI<CobrancaJudicial>> {
        this.getProduto();
        return this.httpClient.post<ReturnAPI<CobrancaJudicial>>(`${WEB_API}/${CobrancaJudicialService.RESOURCE}/cancelar-cobranca/${cobrancaJudicialId}?${this.produtoId}`, null);
    }


    getProduto(): void {
        if (sessionStorage.getItem('produto')) {
            this.produto = JSON.parse(sessionStorage.getItem('produto'));
            this.produtoId = Number.isInteger(this.produto.id) ? 'produtoId=' + this.produto.id : '';
        }
    }

    getRelatorioDePagamentosCobrancaJudicial(options: QueryOptions): Observable<Blob> {
        this.getProduto();
        return this.httpClient.get(
            `${WEB_API}/${CobrancaJudicialService.RESOURCE}/relatorio-pagamentos?${options.toQueryString(false)}&${this.produtoId}`,
            { responseType: 'blob' },
        );
    }

}