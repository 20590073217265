import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CobrancaJudicial } from 'src/app/base/domain/cobranca-judicial.model';



export interface PequenaCausaFormData {
  cobranca: CobrancaJudicial;
  action: string;
}

@Component({
  selector: 'app-alterar-parcela-cobranca-judicial-form',
  templateUrl: './alterar-parcela-cobranca-judicial-form.component.html',
  styleUrls: ['./alterar-parcela-cobranca-judicial-form.component.css']
})
export class AlterarParcelaCobrancaJudicialFormComponent implements OnInit {

  cobrancaJudicial = new CobrancaJudicial();
  title: string;
  valor: number;


  constructor(public dialogRef: MatDialogRef<AlterarParcelaCobrancaJudicialFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: PequenaCausaFormData) {
    this.title = 'Alterar Quantidade de Parcelas';
    this.cobrancaJudicial = this.data.cobranca;
  }

  ngOnInit(): void {
  }

  onConfirm(): void {
    this.cobrancaJudicial.quantidadeParcelas = this.cobrancaJudicial.quantidadeParcelas;
    this.dialogRef.close(this.cobrancaJudicial);
  }

  onCancel(): void {
    this.dialogRef.close();
  }

}
